import React from 'react';

const LindsayWilliamsBio = () => (
    <span>
        <strong>Dr. Lindsay Williams</strong> is a leader in sleep and airway medicine, committed to 
        improving patients’ health through innovative treatments. She earned her Doctorate of Dental Surgery 
        from the University of Maryland Baltimore College of Dental Surgery in 2009 after completing a Bachelor 
        of Arts in Biology at Case Western Reserve University in 2005.
        <br />
        <br />
        After eight years in general dentistry, specializing in cosmetic and restorative care, Dr. Williams 
        shifted her focus to sleep and airway medicine in 2018. She joined the Sleep and Airway Medicine Centers 
        at Highlands Ranch, where she now exclusively treats sleep and airway patients, applying advanced 
        techniques to improve quality of life for both adults and children. 
        <br />
        <br />
        A respected educator and speaker, Dr. Williams has been a top lecturer on the Vivos core curriculum 
        since 2019 and serves on the Vivos Curriculum Committee. Since 2022, she has been a Key Opinion Leader, 
        advocating for airway-focused treatment and sharing her expertise through speaking engagements. 
        <br />
        <br />
        Dr. Williams is also the Principal Investigator and a treating provider in an ongoing Vivos Clinical 
        Study, launched in 2024, focused on Sleep and Breathing conditions in children. Her research contributes 
        to advancing pediatric airway health and treatment options. 
        <br />
        <br />
        Through her clinical work, research, and education, Dr. Williams continues to be a leading authority in 
        sleep and airway medicine, dedicated to improving patient outcomes and advancing the field. 
    </span>
);

export default LindsayWilliamsBio;