// Entities
import ProviderPatient from '../entities/ProviderPatient';
import { MyoCorrectStatus, PatientFilters, TreatmentStatus } from '../entities/Enums';
import Patient from '../entities/Patient';

// API 
import { Gateway } from './Gateway';
import * as _ from 'lodash';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import jwt_decode from 'jwt-decode';
import buildQuery from 'odata-query';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { MainStore } from '../stores/OldStores/MainStore';


export class PatientStore {
    @observable private _basePatientData: any[] = [];
    @observable private _patientData: ProviderPatient[] = [];
    @observable private _resultCount: number = 0;
    @observable private _toggleShowSearch: boolean = false;
    @observable private _toggleShowFilterResults: boolean = false;
    @observable private _toggleShowQuickddPatientsModal: boolean = false;
    @observable private _toggleShowAddPatientsModal: boolean = false;
    @observable private _toggleShowAddPatientFilesModal: boolean = false;
    @observable private _toggleShowCancelWarningModal: boolean = false;
    @observable private _toggleShowPrescriptionModal: boolean = false;
    @observable private _toggleShowPatientEditModal: boolean = false;
    @observable private _toggleShowAiReportOrderModal: boolean = false;
    @observable private _currentPatientId: number = 0;
    @observable private _currentPatient: {} = {};
    @observable private _totalPages: number = 1;
    @observable private _isLeftNavExpanded: boolean = false;
    @observable private _hasMore: boolean = true;
    @observable private _firstPageLoaded: boolean = false;
    @observable _currentPage: number = 0;
    @observable private _selectedVivosId: string = '';
    @observable _currentRow: any;
    @observable private _currentSubNavItem: number = 0;
    @observable _totalPatients: number = 0;
    @observable private _currentFilter;
    @observable didInitPatients = false;
    @observable isLoadingPatients = false;
    @observable loadingPatientsProgress = 0;
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    @observable searchParam = '';
    @observable filter: any = {};
    @observable numPendingTreatment: number = 0;
    @observable numActivePatients: number = 0;
    @observable numAppliancePatients: number = 0;
    @observable numMyoPatients: number = 0;
    @observable numCompletedPatients: number = 0;

    @observable

    userId: number = 0;
    userRoles: any;
    pageSize: number = 200;
    mainStore: MainStore;

    private _totalRows: number = 0;
    get totalRows(): number {
        return this._totalRows;
    }
    set totalRows(value: any) {
        this._totalRows = value;
    }

    @observable treatmentStatusList: any[] = [];
    @action setTreatmentStatusList = (newValue: any[]) => { this.treatmentStatusList = newValue }

    constructor(mainstore?: MainStore) {
        makeAutoObservable(this);
        if (mainstore) {
            this.mainStore = mainstore;
        }
        else {
            this.mainStore = new MainStore();
        }
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
        }
    }

    get origPatientData(): ProviderPatient[] {
        return this._basePatientData;
    }

    @action
    set origPatientData(value: any[]) {
        this._basePatientData = value;
    }

    get selectedVivosId(): string {
        return this._selectedVivosId;
    }

    @action
    set selectedVivosId(value: string) {
        this._selectedVivosId = value;
    }

    get currentRow(): any {
        return this._currentRow;
    }

    @action
    set currentRow(value: any) {
        this._currentRow = value;
    }
    get patientData(): ProviderPatient[] {
        return this._patientData;
    }
    @action setPatientData = newPatientData => (this.patientData = newPatientData);

    @action
    set patientData(value: ProviderPatient[]) {
        this._patientData = value;
    }

    get firstPageLoaded(): boolean {
        return this._firstPageLoaded;
    }

    @action
    set firstPageLoaded(value: any) {
        this._firstPageLoaded = value;
    }

    get currentPage(): number {
        return this._currentPage;
    }

    @action
    set currentPage(value: any) {
        this._currentPage = value;
    }

    get currentSubNavItem(): number {
        return this._currentSubNavItem;
    }

    @action
    set currentSubNavItem(value: any) {
        this._currentSubNavItem = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    @action
    set totalPages(value: any) {
        this._totalPages = value;
    }

    get resultCount() {
        return this._resultCount;
    }

    @action
    set resultCount(value: any) {
        this._resultCount = value;
    }

    get toggleShowSearch() {
        return this._toggleShowSearch;
    }

    @action
    set toggleShowSearch(value: any) {
        this._toggleShowSearch = value;
    }

    get toggleShowFilterResults() {
        return this._toggleShowFilterResults;
    }

    @action
    set toggleShowFilterResults(value: any) {
        this._toggleShowFilterResults = value;
    }

    get toggleShowAddPatientsModal() {
        return this._toggleShowAddPatientsModal;
    }

    @action
    set toggleShowAddPatientsModal(value: any) {
        this._toggleShowAddPatientsModal = value;
    }

    get toggleShowAddPatientFilesModal() {
        return this._toggleShowAddPatientFilesModal;
    }

    @action
    set toggleShowQuickddPatientsModal(value: any) {
        this._toggleShowQuickddPatientsModal = value;
    }

    get toggleShowQuickddPatientsModal() {
        return this._toggleShowQuickddPatientsModal;
    }

    @action
    set toggleShowAddPatientFilesModal(value: any) {
        this._toggleShowAddPatientFilesModal = value;
    }

    get toggleShowPatientEditModal() {
        return this._toggleShowPatientEditModal;
    }

    @action
    set toggleShowPatientEditModal(value: any) {
        this._toggleShowPatientEditModal = value;
    }

    get toggleShowCancelWarningModal() {
        return this._toggleShowCancelWarningModal;
    }

    @action
    set toggleShowCancelWarningModal(value: any) {
        this._toggleShowCancelWarningModal = value;
    }

    get toggleShowPrescriptionModal() {
        return this._toggleShowPrescriptionModal;
    }

    @action
    set toggleShowPrescriptionModal(value: any) {
        this._toggleShowPrescriptionModal = value;
    }

    @computed
    get toggleShowAiReportOrderModal() {
        return this._toggleShowAiReportOrderModal;
    }

    set toggleShowAiReportOrderModal(value: any) {
        this._toggleShowAiReportOrderModal = value;
    }

    get isLeftNavExpanded() {
        return this._isLeftNavExpanded;
    }

    @action
    set isLeftNavExpanded(value: any) {
        this._isLeftNavExpanded = value;
    }

    get currentPatientId() {
        return this._currentPatientId;
    }

    @action
    set currentPatientId(value: any) {
        this._currentPatientId = value;
    }

    get currentPatient() {
        return this._currentPatient;
    }

    @action
    set currentPatient(value: any) {
        this._currentPatient = value;
    }

    get hasMore(): boolean {
        return this._hasMore;
    }

    @action
    set hasMore(value: any) {
        this._hasMore = value;
    }

    get totalPatients(): number {
        return this._totalPatients;
    }

    @action
    set totalPatients(value: number) {
        this._totalPatients = value;
    }

    @action
    get currentFilter(): PatientFilters {
        return this._currentFilter;
    }

    set currentFilter(value: any) {
        this._currentFilter = value;
    }

    @action
    // onFilter = (filters: any): any => {
    //     this._patientData = this.filterResults(this.origPatientData, filters);
    //     this.resultCount = this.patientData.length;
    // };
    onFilter = (value, filter: any) => {
        if (value) {
            this.setPatientData(this.filterPatients(this.origPatientData, filter));
            this.currentFilter = filter;

        } else {
            this.currentFilter = 0;
            this.patientData = this.origPatientData;
            this.filter = {};
        }
        this.loadPatients();
    };



    @action
    onFilterCancel = () => {
        this.toggleFilterResultsExpand();
        this.patientData = this.origPatientData;
        this.resultCount = this.origPatientData.length;
    };

    @action
    toggleFilterResultsExpand = () => {
        this.toggleShowFilterResults = !this.toggleShowFilterResults;
    };

    @action
    handleCancelClose = (doCancel: boolean) => {
        // this.toggleCancelWarningModal();
        // if (doCancel === true) {
        this.toggleAddPatientModal();
        // }
    };

    @action
    toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this._isLeftNavExpanded = isLeftNavExpanded;
    };

    @action
    toggleAddPatientModal = () => {
        runInAction(() => {
            this.toggleShowAddPatientsModal = !this.toggleShowAddPatientsModal;
        });
    };

    @action
    toggleQuickAddModal = () => {
        runInAction(() => {
            this.toggleShowQuickddPatientsModal = !this.toggleShowQuickddPatientsModal;
        });
    };

    @action
    toggleEditPatientModal = () => {
        runInAction(() => {
            this.toggleShowPatientEditModal = !this.toggleShowPatientEditModal;
        });
    };

    @action
    toggleCancelWarningModal = () => {
        runInAction(() => {
            this.toggleShowCancelWarningModal = !this.toggleShowCancelWarningModal;
        });
    };

    @action
    togglePrescriptionModal = () => {
        runInAction(() => {
            this._toggleShowPrescriptionModal = !this._toggleShowPrescriptionModal;
        });
    };

    @action
    toggleAiReportOrderModal = () => {
        runInAction(() => {
            this._toggleShowAiReportOrderModal = !this._toggleShowAiReportOrderModal;
        });
    };

    @action
    setCurrentPatientId = id => {
        runInAction(() => {
            this._currentPatientId = id;
        });
    };

    @action
    searchForPatient = () => {
        this.toggleShowSearch = !this.toggleShowSearch;
    };

    @action
    loadPatients = async () => {
        runInAction(() => {
            this.isLoadingPatients = true;
        });
        let skip = this.currentPage * this.pageSize;

        const query = buildQuery({
            top: this.pageSize,
            skip: skip,
            filter: this.filter ? this.filter : {},
            count: true,
            orderBy: this.sort ? this.sort : ['createdOn desc'],
        });

        var url = `patientodata/${this.userId}` + query;
        const resp = await Gateway.get(url);
        let data = resp['value'];
        const totalPatients = resp['@odata.count'] ? resp['@odata.count'] : this.totalPages;
        this.totalRows = totalPatients;

        if (this.didInitPatients) {
            //get the whole data without filtering
            const queryTotal = buildQuery({
                count: true
            });
            url = `patientodata/${this.userId}` + queryTotal;
            const respTotal = await Gateway.get(url);
            let dataTotal = respTotal['value'];
            //pending treatment        
            this.numPendingTreatment = dataTotal.filter(x => x.treatmentStatusId == 164).length;
            this.numActivePatients = dataTotal.filter(x => x.treatmentStatusId == 5 || x.myoCorrectStatusId == 9 || x.myoCorrectStatusId == 7).length;
            this.numAppliancePatients = dataTotal.filter(x => x.treatmentStatusId == 5).length;
            this.numMyoPatients = dataTotal.filter(x => x.myoCorrectStatusId == 9 || x.myoCorrectStatusId == 7 || x.myoCorrectStatusId == 10).length;
            this.numCompletedPatients = dataTotal.filter(x => x.treatmentStatusId == 165).length;
        }


        runInAction(() => {
            if (this.didInitPatients) {
                this.didInitPatients = false;
            }
            this.totalPatients = totalPatients;
            this.patientData = data.map(data => {
                const newData = { ...data };
                newData.name = data.firstName + ' ' + data.lastName;
                return newData;
            });
            this.origPatientData = this.patientData;
            this.isLoadingPatients = false;
        });
    };

    @observable sort: any;
    onSortChange = (sortModel: GridSortModel): any => {
        runInAction(() => {
            if (Object.keys(sortModel).length > 0) {
                runInAction(() => {
                    this.sort = [sortModel[0].field + ' ' + sortModel[0].sort];
                });
            } else {
                this.sort = {};
            }
        });
    };

    @action
    onFilterChange = async (filterModel: GridFilterModel) => {
        this.isLoadingPatients = true;
        let searchFilter = this.buildFilter(filterModel);
        if (Object.keys(searchFilter).length > 0) {
            this.filter = searchFilter;
            //await this.loadPatients();
            //this.didInitPatients = true;
            //this.isLoadingPatients = false;
        } else {
            this.filter = {};
        }
    };

    @action
    buildFilter = (filterModel: GridFilterModel): any => {
        let customFilter = {};
        let linkOperator = filterModel.linkOperator !== undefined ? filterModel.linkOperator : '';
        customFilter[linkOperator] = [];
        filterModel.items.forEach(filter => {
            let currentOdata = {};
            let operator = {};
            let operatorValue = filter.operatorValue ? filter.operatorValue.toLowerCase() : '';
            let shouldNotAdd = true;
            let funcProperty = `tolower(${filter.columnField})`;
            let typeColumn = 'string';
            let actualValue;

            if (filter.columnField.toLowerCase().includes('date')) {
                typeColumn = 'date';
            }
            if (filter.columnField.toLowerCase() == 'createdon') {
                typeColumn = 'date';
            }

            if ((filter.columnField.includes('id') && !filter.columnField.toLowerCase().includes('provider')) || filter.columnField.includes('days')) {
                typeColumn = 'number';
            }


            if (filter.columnField.includes('Id') && !filter.columnField.toLowerCase().includes('provider')) {
                switch (filter.columnField) {
                    case 'myoCorrectStatusId':
                        switch (filter.value.toLowerCase()) {
                            case "not applicable":
                                actualValue = 11;
                                typeColumn = 'list';
                                break;
                            case "enrolled":
                                actualValue = 7;
                                typeColumn = 'list';
                                break;
                            case "in progress":
                                actualValue = 9;
                                typeColumn = 'list';
                                break;
                            case "complete":
                                actualValue = 10;
                                typeColumn = 'list';
                                break;
                            case "refunded":
                                actualValue = 102;
                                typeColumn = 'list';
                                break;
                            case "cancelled":
                                actualValue = 101;
                                typeColumn = 'list';
                                break;
                            case "not enrolled":
                                actualValue = null;
                                typeColumn = 'list';
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'treatmentStatusId':
                        switch (filter.value.toLowerCase()) {
                            case "pending treatment":
                                actualValue = 164;
                                typeColumn = 'list';
                                break;
                            case "in treatment":
                                actualValue = 5;
                                typeColumn = 'list';
                                break;
                            case "completed":
                                actualValue = 165;
                                typeColumn = 'list';
                                break;
                            case "prospect":
                                actualValue = 1255;
                                typeColumn = 'list';
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }

            if (typeColumn === 'date') {
                switch (filter.operatorValue) {
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                    case 'equals':
                    case 'contains':
                        operator['eq'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'not':
                        operator['ne'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'after':
                        operator['gt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrAfter':
                        operator['ge'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'before':
                        operator['lt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrBefore':
                        operator['le'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'list') {
                switch (filter.operatorValue) {
                    case 'is':
                        operator['eq'] = actualValue;
                        break;
                    case 'not':
                        operator['ne'] = actualValue;
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'number' && filter.value) {

                switch (filter.operatorValue) {
                    case '=':
                        operator['eq'] = { type: 'raw', value: filter.value };
                        break;
                    case '!=':
                        operator['ne'] = { type: 'raw', value: filter.value };
                        break;
                    case '>':
                        operator['gt'] = { type: 'raw', value: filter.value };
                        break;
                    case '>=':
                        operator['ge'] = { type: 'raw', value: filter.value };
                        break;
                    case '<':
                        operator['lt'] = { type: 'raw', value: filter.value };
                        break;
                    case '<=':
                        operator['le'] = { type: 'raw', value: filter.value };
                        break;
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    default:
                        break;
                }
            } else {
                switch (filter.operatorValue) {
                    case 'contains':
                    case 'startsWith':
                    case 'endsWith':
                        operator[operatorValue] = _.toLower(filter.value);
                        break;
                    case 'equals':
                        operator['eq'] = _.toLower(filter.value);
                        break;
                    case 'isEmpty':
                        shouldNotAdd = false;
                        operator['lt'] = 1;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isNotEmpty':
                        shouldNotAdd = false;
                        operator['gt'] = 0;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = filter.value;
                        break;
                    case 'not':
                        operator['ne'] = filter.value;
                        break;
                    default:
                        break;
                }
            }

            if (shouldNotAdd) {
                currentOdata[funcProperty] = operator;
            }
            customFilter[linkOperator].push(currentOdata);
        });
        return customFilter;
    };

    loadAll = async () => {
        runInAction(() => {
            this.didInitPatients = true;
            this.isLoadingPatients = true;
        });
        const patients: ProviderPatient[] = [];
        var url = '/patient/' + this.userId + '/' + 0 + '/' + this.pageSize;
        const resp = await Gateway.get(url);
        const pages = resp['pageCount'];
        this.totalPatients = resp['rowCount'];
        patients.push(...resp['results']);
        if (this.totalPatients !== 0) {
            runInAction(() => {
                this.loadingPatientsProgress = (patients.length / this.totalPatients) * 100;
            });
        }
        //this.getFilterNums();

        const promises: Promise<any[]>[] = [];
        let currentPage = 1;
        while (currentPage <= pages) {
            promises.push(
                Gateway.get(`/patient/${this.userId}/${currentPage}/${this.pageSize}`).then(response => {
                    patients.push(...response['results']);
                    runInAction(() => {
                        this.loadingPatientsProgress = (patients.length / this.totalPatients) * 100;
                    });
                    return response;
                }),
            );
            currentPage++;
        }
        await Promise.all(promises);

        runInAction(() => {
            this.isLoadingPatients = false;
            this.patientData = patients;
            this.origPatientData = this.patientData;
            //this.setNumPatientValues();
        });
    };


    loadData = (): any => {
        this.loadAll();
        var url = 'patient/' + this.userId + '/' + this.currentPage + '/' + this.pageSize;

        Gateway.get(url).then(resp => {
            this.totalPages = resp['pageCount'];
            this.patientData = resp['results'];
            this.origPatientData = this.patientData;
            this.firstPageLoaded = true;
            this.setNumPatientValues();
        });
    };

    setNumPatientValues = async () => {
        _.forEach(this.patientData, p => {
            if (p.treatmentStatusId === TreatmentStatus['Pending Treatment']) {
                this.numPendingTreatment += 1;
            }
            if (
                p.treatmentStatusId === TreatmentStatus['In Treatment'] ||
                p.myoCorrectStatusId === MyoCorrectStatus['In Progress'] ||
                p.myoCorrectStatusId === MyoCorrectStatus.Enrolled
            ) {
                this.numActivePatients += 1;
            }
            if (p.treatmentStatusId === TreatmentStatus['In Treatment']) {
                this.numAppliancePatients += 1;
            }
            if (p.myoCorrectStatusId === MyoCorrectStatus['In Progress'] ||
                p.myoCorrectStatusId === MyoCorrectStatus.Enrolled ||
                p.myoCorrectStatusId === MyoCorrectStatus.Complete) {
                this.numMyoPatients += 1;
            }
            if (p.treatmentStatusId === TreatmentStatus.Completed) {
                this.numCompletedPatients += 1;
            }
        });
    };


    filterPatients = (patientData: any, filter: PatientFilters) => {
        let data: Patient[] = [];
        switch (filter) {
            case PatientFilters.PendingTreatment:
                runInAction(() => {
                    this.filter = { treatmentStatusId: { eq: TreatmentStatus['Pending Treatment'] } };
                });
                break;
            case PatientFilters.ActiveTreatment:
                runInAction(() => {
                    this.filter = {
                        or: [
                            { treatmentStatusId: { eq: TreatmentStatus['In Treatment'] } },
                            { myoCorrectStatusId: { eq: MyoCorrectStatus['In Progress'] } },
                            { myoCorrectStatusId: { eq: MyoCorrectStatus.Enrolled } },
                        ],
                    }
                });
                break;
            case PatientFilters.CompletedPatients:
                runInAction(() => {
                    this.filter = { treatmentStatusId: { eq: TreatmentStatus.Completed } };
                });
                break;
            case PatientFilters.MyoPatients:
                runInAction(() => {
                    this.filter = {
                        or: [
                            { myoCorrectStatusId: { eq: MyoCorrectStatus.Enrolled } },
                            { myoCorrectStatusId: { eq: MyoCorrectStatus['In Progress'] } },
                            { myoCorrectStatusId: { eq: MyoCorrectStatus.Complete } },
                        ],
                    };
                });
                break;
            case PatientFilters.ApplianceTreatment:
                runInAction(() => {
                    this.filter = { treatmentStatusId: { eq: TreatmentStatus['In Treatment'] } };
                });
                break;
            default:
                runInAction(() => {
                    this.filter = {};
                });
                break;
        }
        return data;
    };


    // @action
    // loadPatient = () => {
    //     Gateway.get('/patient/' + this.currentPatientId).then(data => {
    //         this.currentPatient = data;
    //     });
    // };

    onSubNavClick = (filter: number, selectedSubNavItem: number) => {
        this.currentSubNavItem = selectedSubNavItem;
        if (filter === 0) {
            this._patientData = this.origPatientData;
            return;
        }
        this._patientData = _.filter(this.origPatientData, p => {
            return p.treatmentStatusId == filter;
        });
    };
    static TO_NAME = 1;
    static TO_ABBREVIATED = 2;

    filterResults = (patientData: any, filters: any) => {
        var filteredData = _.filter(patientData, p => {
            var ts = filters.treatmentStatus >= 0;
            var ms = filters.myoCorrectStatus >= 0;
            var ais = filters.aiReportStatus >= 0;
            var isp = filters.isProspective >= 0;
            if (ts || ms || ais || isp) {
                if (ts) {
                    if (ms && ais && isp) {
                        return (
                            ((filters.isProspective === 1 && p.isProspect) || (filters.isProspective === 2 && !p.isProspect)) &&
                            p.treatmentStatus === filters.treatmentStatus &&
                            p.myoCorrectStatus === filters.myoCorrectStatus &&
                            p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    if (ms && ais) {
                        return (
                            p.treatmentStatus === filters.treatmentStatus &&
                            p.myoCorrectStatus === filters.myoCorrectStatus &&
                            p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    if (ms) {
                        return (
                            p.treatmentStatus === filters.treatmentStatus &&
                            p.myoCorrectStatus === filters.myoCorrectStatus
                        );
                    }
                    if (ais) {
                        return (
                            p.treatmentStatus === filters.treatmentStatus && p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    return p.treatmentStatus === filters.treatmentStatus;
                } else if (ms) {
                    if (ais && isp) {
                        return (
                            ((filters.isProspective === 1 && p.isProspect) || (filters.isProspective === 2 && !p.isProspect)) &&
                            p.myoCorrectStatus === filters.myoCorrectStatus &&
                            p.aiReportStatus === filters.aiReportStatus
                        );
                    } else if (ais) {
                        return (
                            p.myoCorrectStatus === filters.myoCorrectStatus &&
                            p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    return p.myoCorrectStatus === filters.myoCorrectStatus;
                } else if (ais) {
                    if (isp) {
                        return (
                            ((filters.isProspective === 1 && p.isProspect) || (filters.isProspective === 2 && !p.isProspect)) && p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    return p.aiReportStatus === filters.aiReportStatus;
                } else if (isp) {
                    return ((filters.isProspective === 1 && p.isProspect) || (filters.isProspective === 2 && !p.isProspect));
                }
            }
        });
        return (filters.treatmentStatus >= 0 || filters.myoCorrectStatus >= 0 || filters.aiReportStatus >= 0 || filters.isProspective >= 0) &&
            filteredData.length >= 0
            ? filteredData
            : patientData;
    };

    togglePatientProfileModal = () => {
        //     runInAction(() => {
        //         this.toggleShowDetailsPanelModal = !this.toggleShowDetailsPanelModal;
        //         if (!this.toggleShowDetailsPanelModal) {
        //             this.loadPatient(this.currentRow.vivosId);
        //         }
        //     })
    };

    @action
    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.patientData;
    };

    @action filterBySearchString = () => {
        this.patientData = this.origPatientData.filter(
            x =>
                x.vivosId.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.firstName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.lastName.toString().toLowerCase().includes(this.searchParam.toLowerCase())
        );
    };

    @action
    currentRowToPerson = (currentRow: any) => {
        var person = new Patient({
            id: currentRow.id,
            orgId: currentRow.organizationId,
            providerId: currentRow.providerId,
            vivosId: currentRow.vivosId,
            firstName: currentRow.firstName,
            middleName: currentRow.middleName,
            lastName: currentRow.lastName,
            dateOfBirth: currentRow.dateOfBirth,
            ethnicityId: currentRow.ethnicityId,
            isProspect: currentRow.isProspect,
            isLincare: currentRow.isLincare,
            genderId: currentRow.genderId,
            ehrsId: currentRow.ehrsId,
            primaryPhone: currentRow.primaryPhone,
            altPhone: currentRow.altPhone,
            emailAddress: currentRow.emailAddress,
            treatmentStatusId: currentRow.treatmentStatusId,
        });
        return person;
    };

    @action
    updatePerson = (currentRow: any) => {
        var person = this.currentRowToPerson(currentRow);
        Gateway.post('/patient/person/' + person.id, person).then();
    };

    @action
    handleTreatmentStatusChange = (patientId, treatmentStatusId) => {
        let url = `patient/updatePatientTreatmentStatus/${patientId}/${treatmentStatusId}`;
        Gateway.post(url, null).then(resp => {
            this.currentPatient.treatmentStatusId = treatmentStatusId;
            this.setPatientData(this.currentPatient);
        });
    };
}
