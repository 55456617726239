import React from 'react';

const TaraGriffinBio = () => (
    <span>
        <strong>Dr. Tara M. Griffin</strong>, a graduate of the University of Alabama and Nova Southeastern University,
        is the co-founder of Sleep Solution Centers in Medical City, Orlando, Florida and is internationally
        certified to train doctors for the treatment and correction of Obstructive Sleep Apnea/Sleep
        Breathing Disorder, Enhanced Facial Esthetics, and TMJ Disorders using the DNA, mRNA, and VIVOS
        Appliances™. She has obtained a Double Diplomate from both the American Board of Dental Sleep
        Medicine and the American Sleep and Breathing Academy. Dr. Griffin has been a Clinical Advisor
        with Vivos Therapeutics, Inc. since 2016 and her passion is helping to rid the world of sleep apnea.
        <br />
        <br />
        For whole body health solutions, Dr. Griffin holds a certification in Traditional Chinese Medicine,
        Neurocranial Release Technique, and Cranial Osteopathy with The Cranial Academy.  Dr. Griffin has studied
        with the American Academy for Oral Systemic Health, the Academy of Functional Medicine and Genomics,
        and the American Academy of Orofacial Pain.
        <br />
        <br />
        From 2004 to 2023, Dr Griffin founded and operated Emerald Coast Dental Spa and Sleep Medicine in Panama
        City Beach, Florida.  This is where she spent over a decade developing her skills and passion for the
        treatment of sleep breathing disorders in her general dental practice. Dr. Griffin has been nominated
        Business Woman of the Year by Emerald Ladies Journal, awarded Small Business Person of the Year by Panama
        City Beach Chamber of Commerce, and “Best Sleep Clinic” by Panama City Award Program. In 2016, she spoke
        at The Harvard Faculty Club as an expert in Dental Sleep Medicine. Dr. Griffin has many medical abstracts
        that have been published in national sleep journals with one of her most recent and her Mission is to help
        Revolutionize Healthcare Worldwide through the diagnosis and treatment of sleep disordered breathing!
    </span>
);

export default TaraGriffinBio;