import React from 'react';

const BaharEsmailiBio = () => (
    <span>
        <strong>Dr. Bahar Esmaili</strong> is a dedicated healthcare professional who immigrated to the US 
        from Iran at 20 to pursue her dream of becoming a doctor.  After graduating with honors from the 
        University of Colorado School of Dental Medicine in 2009, she focused on the connection between oral 
        health and overall well-being through advanced training in craniofacial pain, TMJ disorders, and airway 
        development.  
        <br />
        <br />
        As one of the 24 North American consultants for 3M’s Council of Innovative Dentistry and a 
        member of the AACP’s educational committee, she combines her roles as a practitioner and educator.  
        A certified personal trainer specializing in posture correction.  Dr Esmail is on a mission to enlighten 
        others about the body’s interconnectedness and inspire both patients and professionals in their journey 
        to better health.    
        <br />
        <br />
        Dr. Bahar Esmaili, is a dentist specializing in airway and TMJ. She currently limits her practice to 
        treating extremely complex breathing / airway cases with multiple debilitating comorbidities. Patients 
        seek her expertise from all over the world and come to see her in Denver, Colorado, and Dubai, where she 
        handles all of the Middle Eastern clinical training for Vivos Therapeutics.  Dr. Esmaili is VP of Clinical 
        Innovation at Vivos and serves as a member of the Colorado State Board of Dental Examiners.
    </span>
);

export default  BaharEsmailiBio;